import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { alpha, styled } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { createAdmin } from "utils/api";
import { updateAdmin } from "utils/api";
import { getAdmin } from "utils/api";
import SuccessAlert from "layouts/error/success";

function AdminsF() {
  const adminId = localStorage.getItem("adminId");
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
    email: "",
    password: "",
    adminId: adminId,
    isActive: false,
  });
  const [isNewEntry, setIsNewEntry] = useState(true);

  const YellowSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: yellow[700],
      "&:hover": {
        backgroundColor: alpha(yellow[700], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: yellow[700],
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAdmin();
        const data = res.data.data;
        const row = data.find((item) => item.adminId === id);
        if (row) {
          setFormData(row);
          setIsNewEntry(false);
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.username ||
      !formData.phoneNumber ||
      // !formData.password ||
      !formData.email
    ) {
      alert("Please fill all required fields");
    } else {
      if (isNewEntry) {
        try {
          setIsLoading(true);

          await createAdmin(
            formData.firstName,
            formData.lastName,
            formData.username,
            formData.phoneNumber,
            formData.email,
            formData.password,
            formData.isActive
          );
          setIsLoading(false);

          setSuccess("Saved Successfully!");
        } catch (error) {
          setIsLoading(false);

          alert("Error creating Admin");
        }
      } else {
        try {
          setIsLoading(true);

          await updateAdmin(
            formData.firstName,
            formData.lastName,
            formData.username,
            formData.phoneNumber,
            formData.email,
            formData.adminId,
            formData.isActive
          );
          setIsLoading(false);

          setSuccess("Saved Successfully!");
        } catch (error) {
          setIsLoading(false);

          alert("Error Updating Admin");
        }
      }
    }
  };

  return (
    <DashboardLayout>
      {success && <SuccessAlert message={success} link="admins" />}

      <DashboardNavbar />

      {isLoading ? (
        <div className="flex items-center justify-center mt-20">
          <svg
            aria-hidden="true"
            className="mt-20 inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <MDBox py={3} px={15}>
          <div className="bg-white rounded-lg shadow">
            <div className="flex">
              <div className="flex-1 py-5 pl-5 overflow-hidden">
                <h1 className=" text-lg font-semibold">
                  {isNewEntry ? "Create Admin" : "Update Admin"}
                </h1>
              </div>
            </div>
            <div className="px-5 pb-5">
              <input
                placeholder="First name"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
              />
              <input
                placeholder="Last name"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
              />
              <div className="flex">
                <div className="flex-grow w-1/3 pr-2">
                  <input
                    placeholder="Username"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                  />
                </div>
                <div className="flex-grow">
                  <input
                    placeholder="Phone number"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    type="number"
                    className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-grow w-1/4 pr-2">
                  <input
                    placeholder="Email"
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                  />
                  {isNewEntry ? (
                    <div className="flex-grow">
                      <input
                        placeholder="Password"
                        id="passwword"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        type="password"
                        className=" text-black placeholder-gray-600 w-full px-4 py-3.5 mt-2 text-sm   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#ECFFBA]  focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <label className="ml-2 py-1.5 mt-4 text-sm">
                  Active:
                  <br />
                </label>
                <YellowSwitch
                  className="mt-3.5 mr-2"
                  name="isActive"
                  id="isActive"
                  value={formData.isActive}
                  checked={formData.isActive === true}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      isActive: e.target.checked ? true : false,
                    })
                  }
                />
              </div>
            </div>

            <hr className="mt-2" />
            <div className="flex flex-row-reverse p-3">
              <div className="flex-initial pl-3">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="flex items-center px-1 py-1 text-sm  text-white capitalize bg-green-900 rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out"
                >
                  <span className="m-1">{isNewEntry ? "Save" : "Update"}</span>
                </button>
              </div>
            </div>
          </div>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default AdminsF;
