import { useEffect } from "react";

function Out() {
  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("adminId");
      window.location.href = "/login";
    };

    fetchData();
  }, []);
  return <div></div>;
}

export default Out;
