import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

import React, { useEffect, useState } from "react";
import { getTotalTransact } from "utils/api";
import dash from "./icons";
import { getStat } from "utils/api";

function Dashboard() {
  const today = new Date();
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(today.getDate() - 2);

  const [startDate, setStartDate] = useState(twoDaysAgo.toISOString().substr(0, 10));
  const [endDate, setEndDate] = useState(today.toISOString().substr(0, 10));
  const [transactionData, setTransactionData] = useState({
    transactions: [],
    totalTransactionCount: 0,
  });
  const [stat, setStat] = useState({
    totalSignedUpUsers: 0,
    totalActiveUsers: 0,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getStat();

        setStat(res.data.data);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (startDate && endDate) {
        try {
          const res = await getTotalTransact(formatDate(startDate), formatDate(endDate));
          setTransactionData(res.data.data);

          const res2 = {
            status: true,
            statusCode: 200,
            message: "Customer statistics retrieved successfully.",
            data: {
              totalSignedUpUsers: 4,
              totalActiveUsers: 0,
            },
          };

          setStat(res2.data);

          console.log(stat);
        } catch (error) {
          console.error("Error fetching transaction data:", error);
        }
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  const renderTransactions = (
    <table className="m-3 w-90% text-sm">
      <thead>
        <tr>
          <th className="text-left">Transaction Reference</th>
          <th className="text-left">Customer Name</th>
          <th className="text-center">Amount</th>
          <th className="text-right">Description</th>
        </tr>
      </thead>
      <tbody>
        {transactionData.transactions.slice(0, 3).map((transaction) => (
          <tr key={transaction.transactionReference}>
            <td className="text-left">{transaction.transactionReference}</td>
            <td className="text-left">{transaction.beneficiaryName}</td>
            <td className="text-center">{transaction.amount}</td>
            <td className="text-right">{transaction.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderRoutes = dash.map(({ name, icon, route }) => {
    let returnValue;

    if (name === "Customers") {
      returnValue = (
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="6rem"
                  mt={-3}
                >
                  <Icon fontSize="medium" color="inherit">
                    {icon}
                  </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    {name}
                  </MDTypography>
                  <MDTypography variant="h4">{}</MDTypography>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pb={2} px={2}>
                <MDTypography component="p" variant="button" color="text" display="flex">
                  {`Total number of ${name}: `}{" "}
                  <span className="font-bold ml-2">{stat.totalSignedUpUsers}</span>
                </MDTypography>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      );
    } else {
      returnValue = (
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="6rem"
                  mt={-3}
                >
                  <Icon fontSize="medium" color="inherit">
                    {icon}
                  </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    {name}
                  </MDTypography>
                  <MDTypography variant="h4">{}</MDTypography>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pb={2} px={2}>
                <MDTypography component="p" variant="button" color="text" display="flex">
                  {`Total number of ${name}: `}{" "}
                  <span className="font-bold ml-2">{stat.totalActiveUsers}</span>
                </MDTypography>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      );
    }

    return returnValue;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {renderRoutes}
        </Grid>
        <Card>
          <MDBox p={2}>
            <label htmlFor="start-date" className="text-xs">
              Start Date:
            </label>
            <input
              type="date"
              id="start-date"
              className="text-xs border rounded p-1"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value)}
            />

            <label htmlFor="end-date" className="text-xs">
              End Date:
            </label>
            <input
              type="date"
              id="end-date"
              className="text-xs border rounded p-1"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value)}
            />
            <MDTypography variant="subtitle1">
              Transactions within selected date range: {transactionData.totalTransactionCount}
            </MDTypography>
          </MDBox>
        </Card>
        <Card className="mt-2">{renderTransactions}</Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
