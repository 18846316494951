import Icon from "@mui/material/Icon";

const dash = [
  {
    type: "collapse",
    name: "Customers",
    key: "savings",
    icon: <Icon fontSize="small">person</Icon>,
  },

  {
    type: "collapse",
    name: "Active Customers",
    key: "customers",
    icon: <Icon fontSize="small">accessibility</Icon>,
  },
];

export default dash;
