import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const SuccessAlert = ({ message, link }) => {
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgb(255 255 255 / 76%)",
    zIndex: 9999,
    display: showAlert ? "block" : "none",
  };

  const successAlertStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#48bb78",
    color: "#ffffff",
    padding: "1rem",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10000,
    display: showAlert ? "flex" : "none",
  };

  const handleCloseClick = () => {
    setShowAlert(false);
    // window.location.href = `/${link}`;
    navigate(`/${link}`);
  };

  return (
    <>
      <div style={overlayStyle}></div>
      <div className="flex flex-col items-end" style={successAlertStyle}>
        <strong>{message}</strong>
        <button
          className="text-sm ml-4 px-1 py-0.2 border border-white rounded"
          onClick={handleCloseClick}
        >
          Close
        </button>
      </div>
    </>
  );
};

SuccessAlert.propTypes = {
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SuccessAlert;
