import AdminsF from "layouts/admins/form";
// import AdminsT from "layouts/admins/table";
import Customer from "layouts/customer";
import CustomerDetails from "layouts/customer/list";
import CTra from "layouts/customer/trans";
// import InvestPF from "layouts/iprice/form";
// import InvestPT from "layouts/iprice/table";
// import InvestF from "layouts/itype/form";
// import InvestT from "layouts/itype/table";
// import LoanF from "layouts/loans/form";
// import LoanT from "layouts/loans/table";
import Login from "layouts/login";
import SavingsF from "layouts/savings/form";

const routesPrime = [
  {
    route: "/create-savings/:id",
    component: <SavingsF />,
    key: "savings",
  },
  // {
  //   route: "/list-loans",
  //   component: <LoanT />,
  //   key: "savings",
  // },
  // {
  //   route: "/create-loan/:id",
  //   component: <LoanF />,
  //   key: "savings",
  // },
  // {
  //   route: "/list-investments",
  //   component: <InvestT />,
  //   key: "savings",
  // },
  // {
  //   route: "/create-investment/:id",
  //   component: <InvestF />,
  //   key: "savings",
  // },
  // {
  //   route: "/list-investment-price",
  //   component: <InvestPT />,
  //   key: "savings",
  // },
  // {
  //   route: "/create-investment-price/:id",
  //   component: <InvestPF />,
  //   key: "savings",
  // },
  // {
  //   route: "/list-admins",
  //   component: <AdminsT />,
  //   key: "savings",
  // },
  {
    route: "/create-admin/:id",
    component: <AdminsF />,
    key: "savings",
  },
  {
    route: "/customer-tile/:id",
    component: <Customer />,
    key: "savings",
  },
  {
    route: "/customer-transactions/:id",
    component: <CTra />,
    key: "savings",
  },
  // {
  //   route: "/customer-loans/:id",
  //   component: <CustomerDetails />,
  //   key: "savings",
  // },
  {
    route: "/customer-details/:id",
    component: <CustomerDetails />,
    key: "savings",
  },
  // {
  //   route: "/customer-portfolios/:id",
  //   component: <CustomerDetails />,
  //   key: "savings",
  // },
  // {
  //   route: "/customer-savings/:id",
  //   component: <CustomerDetails />,
  //   key: "savings",
  // },
  // {
  //   route: "/customer-withdrawals/:id",
  //   component: <CustomerDetails />,
  //   key: "savings",
  // },
  {
    route: "/login",
    component: <Login />,
    key: "savings",
  },
];

export default routesPrime;
