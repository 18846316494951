import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { getAllCustomers } from "utils/api";

function CustomerAll() {
  const [customers, setCustomers] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    firstName: "",
    lastName: "",
    bankName: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const adminId = localStorage.getItem("adminId");

  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 20;

  const fetchData = async () => {
    try {
      const res = await getAllCustomers(adminId);
      setCustomers(res.data.data);
      setIsLoading(false);
    } catch (error) {
      // alert("Error getting All Customers");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleEdit = (id) => {
    window.location.href = `/customer-tile/${id}`;
  };

  const handleFilterApply = () => {
    const filteredCustomers = customers.filter((customer) => {
      const firstNameMatch = customer.firstName
        .toLowerCase()
        .includes(filterCriteria.firstName.toLowerCase());
      const lastNameMatch = customer.lastName
        .toLowerCase()
        .includes(filterCriteria.lastName.toLowerCase());
      const bankNameMatch = customer.bankName
        .toLowerCase()
        .includes(filterCriteria.bankName.toLowerCase());
      return firstNameMatch && lastNameMatch && bankNameMatch;
    });

    setCustomers(filteredCustomers);
  };
  const handleClearFilter = () => {
    setFilterCriteria({
      firstName: "",
      lastName: "",
      accountNumber: "",
      bankName: "",
    });
    fetchData();
  };

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = customers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {isLoading ? (
        <div className="flex items-center justify-center mt-20">
          <svg
            aria-hidden="true"
            className="mt-20 inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          <div className="flex justify-end">
            <button
              className="mr-3 bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={handleFilterApply}
            >
              Filter
            </button>
            <button
              className="bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
          </div>
          <div className="mt-2 flex justify-end">
            <input
              className="w-[7vw] text-sm mr-4"
              type="text"
              placeholder="First Name"
              value={filterCriteria.firstName}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  firstName: e.target.value,
                }))
              }
            />
            <input
              className="w-[7vw] text-sm mr-4"
              type="text"
              placeholder="Last Name"
              value={filterCriteria.lastName}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  lastName: e.target.value,
                }))
              }
            />
            <input
              className="w-[9vw] text-sm"
              type="text"
              placeholder="Bank Name"
              value={filterCriteria.bankName}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  bankName: e.target.value,
                }))
              }
            />
          </div>

          <MDBox py={3}>
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-green-900 text-white text-sm leading-normal">
                  <th className="py-3 px-6 text-left">View</th>
                  <th className="py-3 px-6 text-left">Nickname</th>
                  <th className="py-3 px-6 text-left">First name</th>
                  <th className="py-3 px-6 text-left">Last name</th>
                  <th className="py-3 px-6 text-left">Account Number</th>
                  <th className="py-3 px-6 text-left">Bank Name</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {currentCustomers.map((row) => (
                  <tr key={row.id} className="border-b border-gray-200 bg-white hover:bg-[#ECFFBA]">
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div
                          onClick={() => handleEdit(row.userId)}
                          className="w-4 mr-2 transform hover:text-green-900 hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 3a1 1 0 0 1 1 1c0 2.206-1.794 4-4 4a1 1 0 1 1 0-2c.551 0 1-.449 1-1a1 1 0 0 1 2 0zm0 5a3 3 0 0 1-3-3c0-1.654 1.346-3 3-3s3 1.346 3 3a3 3 0 0 1-3 3z" />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="font-medium">{row.nickname}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="font-medium">{row.firstName}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex items-center">
                        <span>{row.lastName}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <span>{row.uniqueAccountNumber}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <span>{row.bankName}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </MDBox>
          <div className="flex justify-between items-center mt-4">
            <button
              className="bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            <span className="text-gray-600 text-sm">
              Page {currentPage} of {Math.ceil(customers.length / customersPerPage)}
            </span>
            <button
              className="bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastCustomer >= customers.length}
            >
              Next Page
            </button>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default CustomerAll;
