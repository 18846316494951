import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";

import { getOneCustomersDetails } from "utils/api";
import { useParams } from "react-router-dom";

function CustomerDetails() {
  const { id } = useParams();
  const adminId = localStorage.getItem("adminId");
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    nickname: "",
    userId: "",
    uniqueAccountNumber: "",
    bankName: "",
    percentageBoarded: "",
    firstName: "",
    emailAddress: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    bvn: "",
    dateOfBirth: "",
    password: "",
    selfieUrl: "",
    profilePicUrl: "",
    residentialAddress: "",
    closestLandmark: "",
    maritalStatus: "",
    gender: "",
    state: "",
    city: "",
    idCardUrl: "",
    utilityBill: "",
    transactionPin: "",
    employmentStatus: "",
    nameOfCompany: "",
    workStartDate: "",
    monthlyIncome: "",
    firstNameOfNxtOfKin: "",
    lastNameOfNxtOfKin: "",
    nxtOfKinPhoneNumber: "",
    nxtOfKinEmailAddress: "",
    relationshipWithNxtOfKin: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOneCustomersDetails(adminId, id);
        const data = res.data.data;
        if (data) {
          setFormData(data);
          setIsLoading(false);
        }
        setIsLoading(false);

        console.log(formData);
      } catch (error) {
        setIsLoading(false);

        console.log(error);
        // alert("Error fetching Customer Details");
        // window.location.href = `/customer`;
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <div className="flex items-center justify-center mt-20">
          <svg
            aria-hidden="true"
            className="mt-20 inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <MDBox py={5} px={15}>
          <div className="bg-white rounded-lg shadow">
            <div className="flex flex-col">
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">Nickname: {formData.nickname}</span>
              </div>
              <div className="mt-3  pl-5 overflow-hidden">
                <span className=" text-base font-semibold">
                  Account Number: {formData.uniqueAccountNumber}
                </span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">Bank Name: {formData.bankName}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">
                  Percentage Boarded: {formData.percentageBoarded}
                </span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">First Name: {formData.firstName}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">Last Name: {formData.lastName}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">Middle Name: {formData.middleName}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">Email: {formData.emailAddress}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">
                  Phone Number: {formData.phoneNumber}
                </span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">BVN: {formData.bvn}</span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">
                  Date of Birth: {formData.dateOfBirth}
                </span>
              </div>
              <div className="mt-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">password: {formData.password}</span>
              </div>
              <div>
                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">Selfie URL: {formData.selfieUrl}</span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Profile Picture URL: {formData.profilePicUrl}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Residential Address: {formData.residentialAddress}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Closest Landmark: {formData.closestLandmark}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Marital Status: {formData.maritalStatus}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">Gender: {formData.gender}</span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">State: {formData.state}</span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">City: {formData.city}</span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">ID Card URL: {formData.idCardUrl}</span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Utility Bill: {formData.utilityBill}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Transaction Pin: {formData.transactionPin}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Employment Status: {formData.employmentStatus}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Name of Company: {formData.nameOfCompany}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Work Start Date: {formData.workStartDate}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Monthly Income: {formData.monthlyIncome}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    First Name of Next of Kin: {formData.firstNameOfNxtOfKin}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Last Name of Next of Kin: {formData.lastNameOfNxtOfKin}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Next of Kin Phone Number: {formData.nxtOfKinPhoneNumber}
                  </span>
                </div>

                <div className="mt-3 pl-5 overflow-hidden">
                  <span className="text-base font-semibold">
                    Next of Kin Email Address: {formData.nxtOfKinEmailAddress}
                  </span>
                </div>
              </div>
              <div className="mt-3 mb-3 pl-5 overflow-hidden">
                <span className=" text-base font-semibold">
                  Next of Kin relationship: {formData.relationshipWithNxtOfKin}
                </span>
              </div>
            </div>
          </div>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default CustomerDetails;
