import ErrorAlert from "layouts/error/modal";
import ErrorRegular from "layouts/error/regular";
import { useEffect, useState } from "react";
import { login } from "utils/api";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorModal, setErrorModal] = useState(null);
  const [errorRegular, setErrorRegular] = useState(null);

  const customBackgroundColor = "#FAFFEE";

  const handleChange = (e) => {
    setErrorRegular(null);
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setErrorRegular("Please fill all required fields");
    } else {
      try {
        const res = await login(formData.email, formData.password);

        window.location.href = "/";
        const { adminId, token } = res.data.data;
        localStorage.setItem("adminId", adminId);
        localStorage.setItem("token", token);
      } catch (error) {
        console.log(error);
        setErrorModal("Error Authenticating User");
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleMouseClick);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("click", handleMouseClick);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const handleMouseClick = () => {
    setErrorModal(null);
  };

  const handleKeyPress = () => {
    setErrorModal(null);
  };

  return (
    <div
      className="h-screen py-6
      flex
      flex-col
      justify-center
      sm:py-12"
      style={{ backgroundColor: customBackgroundColor }}
    >
      {errorRegular && <ErrorRegular message={errorRegular} />}
      {errorModal && <ErrorAlert message={errorModal} />}
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-green-700 to-green-900 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold">Login to Oxigen Admin Dashboard</h1>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div className="relative">
                  <input
                    autoComplete="off"
                    id="email"
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                    placeholder="Email address"
                  />
                  <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                    Email Address
                  </label>
                </div>
                <div className="relative">
                  <input
                    autoComplete="off"
                    id="password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                    placeholder="Password"
                  />
                  <label className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                    Password
                  </label>
                </div>
                <div className="relative">
                  <button
                    onClick={handleSubmit}
                    className="bg-green-900 text-white rounded-md px-2 py-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
