import axios from "axios";

let host;

// host = "https://appdev.leadinsight.ng/mobileadmin";
// host = "http://localhost:3001";
// host = "https://prod_api.oxigen.ng";
host = "https://adminapi.oxigen.com.ng";

const axiosInstance = axios.create({
  baseURL: `${host}`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAuthToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

axiosInstance.interceptors.request.use((config) => {
  const authToken = getAuthToken();
  if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});

export const login = async (emailAddress, password) => {
  return axiosInstance.post(`${host}/api/auth/admin/login`, { emailAddress, password });
};

export const createAdmin = async (
  firstName,
  lastName,
  username,
  phoneNumber,
  email,
  password,
  isActive
) => {
  return axiosInstance.post(`${host}/api/auth/create-admin`, {
    firstName,
    lastName,
    username,
    phoneNumber,
    email,
    password,
    isActive,
  });
};
export const updateAdmin = async (
  firstName,
  lastName,
  username,
  phoneNumber,
  email,
  adminId,
  isActive
) => {
  return axiosInstance.put(`${host}/api/auth/update-admin`, {
    firstName,
    lastName,
    username,
    phoneNumber,
    email,
    adminId,
    isActive,
  });
};
export const getAdmin = async () => {
  return axiosInstance.get(`${host}/api/auth/get-admin-list`);
};
export const deactivateAdmin = async (id) => {
  return axiosInstance.delete(`${host}/api/auth/delete-admin/${id}`);
};

export const createSaving = async (
  onBoardingType,
  fontColor,
  totalBalance,
  productName,
  shortName,
  balance,
  isSubscribed,
  productColor,
  productDescription,
  howDoesItWork,
  interestRate,
  minimumAmount,
  withHoldingTax,
  adminId,
  productIcon,
  imageUrl,
  productImage
) => {
  const formData = new FormData();
  formData.append("productImage", productImage);
  formData.append("productIcon", productIcon);
  formData.append("imageUrl", imageUrl);
  formData.append("onBoardingType", onBoardingType);
  formData.append("fontColor", fontColor);
  formData.append("totalBalance", totalBalance);
  formData.append("productName", productName);
  formData.append("shortName", shortName);
  formData.append("balance", balance);
  formData.append("isSubscribed", isSubscribed);
  formData.append("productColor", productColor);
  formData.append("productDescription", productDescription);
  formData.append("howDoesItWork", howDoesItWork);
  formData.append("interestRate", interestRate);
  formData.append("minimumAmount", minimumAmount);
  formData.append("withHoldingTax", withHoldingTax);
  formData.append("adminId", adminId);

  return axiosInstance.post(`${host}/api/admin/savings/create-savings`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateSavings = async (
  onBoardingType,
  fontColor,
  totalBalance,
  productName,
  shortName,
  balance,
  isSubscribed,
  productColor,
  productDescription,
  howDoesItWork,
  interestRate,
  minimumAmount,
  withHoldingTax,
  adminId,
  productIcon,
  imageUrl,
  productImage
) => {
  const formData = new FormData();
  formData.append("image", productImage);
  formData.append("productIcon", productIcon);
  formData.append("imageUrl", imageUrl);
  formData.append("productName", productName);

  await axiosInstance.post(`${host}/api/admin/savings/upload-images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return axiosInstance.put(
    `${host}/api/admin/savingstype/update-savings`,
    onBoardingType,
    fontColor,
    totalBalance,
    productName,
    shortName,
    balance,
    isSubscribed,
    productColor,
    productDescription,
    howDoesItWork,
    interestRate,
    minimumAmount,
    withHoldingTax,
    adminId
  );
};
export const getSavings = async () => {
  return axiosInstance.get(`${host}/api/admin/savings/get-savings-list`);
};
export const deleteSavings = async (id) => {
  return axiosInstance.delete(`${host}/api/admin/savings/delete-savings/${id}`);
};

export const createInvestmentT = async (
  name,
  code,
  accountName,
  bankName,
  accountNumber,
  currency,
  title,
  description,
  imageUrl,
  investmentId,
  adminUUID
) => {
  return axiosInstance.post(`${host}/api/admin/investment/create-investment`, {
    name,
    code,
    accountName,
    bankName,
    accountNumber,
    currency,
    title,
    description,
    imageUrl,
    investmentId,
    adminUUID,
  });
};
export const updateInvestmentT = async (
  name,
  code,
  accountName,
  bankName,
  accountNumber,
  currency,
  title,
  description,
  imageUrl,
  investmentId,
  adminUUID
) => {
  return axiosInstance.put(
    `${host}/api/investment/update-investment`,
    name,
    code,
    accountName,
    bankName,
    accountNumber,
    currency,
    title,
    description,
    imageUrl,
    investmentId,
    adminUUID
  );
};
export const getInvestmentT = async () => {
  return axiosInstance.get(`${host}/api/investment/get-investment-list`);
};
export const deleteInvestmentT = async (id) => {
  return axiosInstance.delete(`${host}/api/investment/delete-investment/${id}`);
};

export const createInvestmentP = async (
  code,
  price,
  pricechange,
  percent,
  date,
  rating,
  mininvestment,
  returns,
  adminId
) => {
  return axiosInstance.post(`${host}/api/investment/create-incestment-price`, {
    code,
    price,
    pricechange,
    percent,
    date,
    rating,
    mininvestment,
    returns,
    adminId,
  });
};
export const updateInvestmentP = async (
  code,
  price,
  pricechange,
  percent,
  date,
  rating,
  mininvestment,
  returns,
  adminId
) => {
  return axiosInstance.put(`${host}/api/investment/update-investment-price`, {
    code,
    price,
    pricechange,
    percent,
    date,
    rating,
    mininvestment,
    returns,
    adminId,
  });
};
export const getInvestmentP = async () => {
  return axiosInstance.get(`${host}/api/investment/get-investment-price-list`);
};
export const deleteInvestmentP = async (id) => {
  return axiosInstance.delete(`${host}/api/investment/delete-investment-price/${id}`);
};

export const createLoan = async (
  totalLeftToPay,
  loanName,
  loanSubName,
  loanIcon,
  description,
  maxAmount,
  color,
  image,
  howDoesItWork,
  eligibilityCheck,
  interestRate,
  repaymentDate,
  adminId
) => {
  return axiosInstance.post(`${host}/api/loan/create-loan-product`, {
    totalLeftToPay,
    loanName,
    loanSubName,
    loanIcon,
    description,
    maxAmount,
    color,
    image,
    howDoesItWork,
    eligibilityCheck,
    interestRate,
    repaymentDate,
    adminId,
  });
};
export const updateLoan = async (
  totalLeftToPay,
  loanName,
  loanSubName,
  loanIcon,
  description,
  maxAmount,
  color,
  image,
  howDoesItWork,
  eligibilityCheck,
  interestRate,
  repaymentDate,
  adminId
) => {
  return axiosInstance.put(`${host}/api/loan/update-loan-product`, {
    totalLeftToPay,
    loanName,
    loanSubName,
    loanIcon,
    description,
    maxAmount,
    color,
    image,
    howDoesItWork,
    eligibilityCheck,
    interestRate,
    repaymentDate,
    adminId,
  });
};
export const getloan = async () => {
  return axiosInstance.get(`${host}/api/loan/fetch-all`);
};
export const deleteLoan = async (id) => {
  return axiosInstance.delete(`${host}/api/loan/delete-loan-product/${id}`);
};

export const getAllCustomers = async (id) => {
  return axiosInstance.get(`${host}/api/admin/customer/get-customers-with-complete-profile/${id}`);
};
export const getOneCustomersDetails = async (adminId, userId) => {
  return axiosInstance.get(
    `${host}/api/admin/customer/get-customer-detail?adminId=${adminId}&userId=${userId}`
  );
};
export const getOneCustomersLoans = async (adminId, userId) => {
  return axiosInstance.get(
    `${host}/api/admin/customer/get-customer-loans?adminId=${adminId}&userId=${userId}`
  );
};
export const getOneCustomersSavings = async (adminId, userId) => {
  return axiosInstance.get(
    `${host}/api/admin/customer/get-customer-saving?adminId=${adminId}&userId=${userId}`
  );
};
export const getTransactionDetails = async (userId) => {
  return axiosInstance.get(`${host}/api/admin/transaction/by-user/${userId}`);
};
export const getTotalTransact = async (startDateTime, endDateTime) => {
  return axiosInstance.post(`${host}/api/admin/transaction/weekly-transactions`, {
    startDateTime,
    endDateTime,
  });
};
export const getStat = async () => {
  return axiosInstance.get(`${host}/api/admin/customer/get-customer-statistics`);
};
