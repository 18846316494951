import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import menu from "./menu";
import { Link, useParams } from "react-router-dom";

function Customer() {
  const { id } = useParams();

  const renderRoutes = menu.map(({ name, icon, route, text }) => {
    const interpolatedRoute = route.replace(":id", id);
    let returnValue;

    returnValue = (
      <Grid item xs={12} md={6} lg={4}>
        <MDBox mb={1.5}>
          <Link to={interpolatedRoute}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="6rem"
                  mt={-3}
                >
                  <Icon fontSize="medium" color="inherit">
                    {icon}
                  </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    {name}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pb={2} px={2}>
                <MDTypography component="p" variant="button" color="text" display="flex">
                  {text}
                </MDTypography>
              </MDBox>
            </Card>
          </Link>
        </MDBox>
      </Grid>
    );

    return returnValue;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {renderRoutes}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Customer;
