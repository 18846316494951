import React from "react";
import PropTypes from "prop-types";

const ErrorRegular = ({ message, visible }) => {
  const slideAnimation = visible ? "slide-in" : "";

  const divStyle = {
    display: "inline-block",
    margin: "auto",
    width: "auto",
    zIndex: 10000,
  };

  return (
    <div
      className={`error-alert bg-red-500 text-white p-4 rounded-lg shadow-md opacity-90 ${slideAnimation}`}
      style={divStyle}
    >
      <strong>{message}</strong>
    </div>
  );
};

ErrorRegular.propTypes = {
  message: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ErrorRegular;
