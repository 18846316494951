import Icon from "@mui/material/Icon";

const menu = [
  {
    name: "Details",
    text: "Get Customer's Details",
    icon: <Icon fontSize="small">info</Icon>,
    route: "/customer-details/:id",
  },
  // {
  //   name: "Portfolios",
  //   text: "Get Customer's Portfolios",
  //   icon: <Icon fontSize="small">web</Icon>,
  //   route: "/customer-portfolios/:id",
  // },
  // {
  //   name: "Loans",
  //   text: "Get Customer's Loans",
  //   icon: <Icon fontSize="small">money</Icon>,
  //   route: "/customer-loans/:id",
  // },
  // {
  //   name: "Savings",
  //   text: "Get Customer's Savings",
  //   icon: <Icon fontSize="small">savings</Icon>,
  //   route: "/customer-savings/:id",
  // },
  // {
  //   name: "Withdrawals",
  //   text: "Get Customer's Withdrawals",
  //   icon: <Icon fontSize="small">payments</Icon>,
  //   route: "/customer-withdrawals/:id",
  // },
  {
    name: "Transactions",
    text: "Get Customer's Transactions",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/customer-transactions/:id",
  },
];

export default menu;
