import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import "./css/style.css";

import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import routesPrime from "routesPrime";
import Login from "layouts/login";

export default function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const adminId = localStorage.getItem("adminId");

  if (isLoginPage) {
    return <Login />;
  }
  if (!adminId) {
    return <Login />;
  }

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  const getRoutesPrime = (allRoutesPrime) =>
    allRoutesPrime.map((routePrime) => {
      return (
        <Route exact path={routePrime.route} element={routePrime.component} key={routePrime.key} />
      );
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Sidenav
        color={sidenavColor}
        brandName="OXIGEN ADMIN"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Routes>
        {getRoutes(routes)}
        {getRoutesPrime(routesPrime)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
