import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { getloan } from "utils/api";

function LoanT() {
  const [investP, setInvestP] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    staffName: "",
    tenureInDays: "",
    bankName: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [popupData, setPopupData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getloan();

      setInvestP(res.data.data);
      setIsLoading(false);
    } catch (error) {
      // alert("Error getting list of Loans");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (row) => {
    setPopupData(row);
    setShowPopup(true);
  };

  const handleFilterApply = () => {
    const filteredinvestps = investP.filter((investp) => {
      const firstNameMatch = investp.staffName
        .toLowerCase()
        .includes(filterCriteria.staffName.toLowerCase());
      const tenureInDaysmatch = investp.tenureInDays
        .toLowerCase()
        .includes(filterCriteria.tenureInDays.toLowerCase());
      const bankNameMatch = investp.bankName
        .toLowerCase()
        .includes(filterCriteria.bankName.toLowerCase());
      return firstNameMatch && tenureInDaysmatch && bankNameMatch;
    });
    setInvestP(filteredinvestps);
  };
  const handleClearFilter = () => {
    setFilterCriteria({
      staffName: "",
      tenureInDays: "",
      bankName: "",
    });
    fetchData();
  };
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = 20;

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = investP.slice(indexOfFirstCustomer, indexOfLastCustomer);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <div className="flex items-center justify-center mt-20">
          <svg
            aria-hidden="true"
            className="mt-20 inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          <div className="flex justify-end">
            <button
              className="mr-3 bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={handleFilterApply}
            >
              Filter
            </button>
            <button
              className="bg-green-900 font-bold text-white text-sm px-4 py-2 rounded"
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
          </div>
          <div className="mt-2 flex justify-end">
            <input
              className="w-[7vw] p-2 border text-sm mr-4"
              type="text"
              placeholder="Name"
              value={filterCriteria.staffName}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  staffName: e.target.value,
                }))
              }
            />
            <input
              className="w-[7vw] p-2 border text-sm mr-4"
              type="text"
              placeholder="Loan Tenure"
              value={filterCriteria.tenureInDays}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  tenureInDays: e.target.value,
                }))
              }
            />
            <input
              className="w-[9vw] p-2 border text-sm"
              type="text"
              placeholder="Bank Name"
              value={filterCriteria.bankName}
              onChange={(e) =>
                setFilterCriteria((prevFilterCriteria) => ({
                  ...prevFilterCriteria,
                  bankName: e.target.value,
                }))
              }
            />
          </div>
          <MDBox py={3}>
            <div className="overflow-x-auto">
              <table className="min-w-max w-full table-auto">
                <thead>
                  <tr className="bg-green-900 text-white text-sm leading-normal">
                    <th className="py-3 px-6 text-left">View</th>
                    <th className="py-3 px-6 text-left">Customer&apos;s Name</th>
                    <th className="py-3 px-6 text-left">Loan Amount</th>
                    <th className="py-3 px-6 text-left">Loan Tenure</th>
                    <th className="py-3 px-6 text-center">Reason for Loan</th>
                    <th className="py-3 px-6 text-center">Bank name</th>
                    <th className="py-3 px-6 text-center">Date of Loan</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {investP.map((row) => (
                    <tr
                      key={row.loanId}
                      className="border-b border-gray-200 bg-white hover:bg-[#ECFFBA]"
                      // onClick={() => handleRowClick(row)}
                      // style={{ cursor: "pointer" }}
                    >
                      <td className="py-3 px-6 text-center">
                        <div className="flex item-center justify-center">
                          <div
                            onClick={() => handleRowClick(row)}
                            className="w-4 mr-2 transform hover:text-green-900 hover:scale-110"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 3a1 1 0 0 1 1 1c0 2.206-1.794 4-4 4a1 1 0 1 1 0-2c.551 0 1-.449 1-1a1 1 0 0 1 2 0zm0 5a3 3 0 0 1-3-3c0-1.654 1.346-3 3-3s3 1.346 3 3a3 3 0 0 1-3 3z" />
                            </svg>
                          </div>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.staffName}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.loanAmount}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.tenureInDays}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.reasonForLoan}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.bankName}</span>
                        </div>
                      </td>
                      <td className="py-3 px-6 text-left">
                        <div className="flex items-center">
                          <span>{row.dateStarted}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </MDBox>
        </div>
      )}
      {showPopup && popupData && (
        <div className="fixed inset-0 flex items-center justify-center z-500">
          <div className="bg-white p-4 rounded-lg shadow-lg relative mt-[60px] ml-[260px]">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-red-500 mt-2"
              onClick={() => setShowPopup(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <h2 className="text-xl font-semibold">Loan Details</h2>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <ul style={{ fontSize: "16px" }}>
                  {" "}
                  <li>
                    <strong>Customer:</strong> {popupData.staffName}
                  </li>
                  <li>
                    <strong>Bank Name:</strong> {popupData.bankName}
                  </li>
                  <li>
                    <strong>Bank Account No:</strong> {popupData.bankAccountNo}
                  </li>
                  <li>
                    <strong>Residential Address:</strong> {popupData.residentialAddress}
                  </li>
                  <li>
                    <strong>Closest Landmark:</strong> {popupData.closestLandmark}
                  </li>
                  <li>
                    <strong>Next of Kin Name:</strong> {popupData.nextOfKinName}
                  </li>
                  <li>
                    <strong>Relationship with Next of Kin:</strong>{" "}
                    {popupData.relationshipWithNextOfKin}
                  </li>
                  <li>
                    <strong>Next of Kin Phone Number:</strong> {popupData.nextOfKinPhoneNumber}
                  </li>
                  <li>
                    <strong>Employment Status:</strong> {popupData.employmentStatus}
                  </li>
                  <li>
                    <strong>Company Name:</strong> {popupData.companyName}
                  </li>
                  <li>
                    <strong>Sector:</strong> {popupData.sector}
                  </li>
                  <li>
                    <strong>Industry:</strong> {popupData.industry}
                  </li>
                  <li>
                    <strong>Salary Amount:</strong> {popupData.salaryAmount}
                  </li>
                  <li>
                    <strong>Company Address:</strong> {popupData.companyAddress}
                  </li>
                  <li>
                    <strong>State:</strong> {popupData.state}
                  </li>
                </ul>
              </div>

              <div>
                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <strong>LGA:</strong> {popupData.lga}
                  </li>
                  <li>
                    <strong>Apply for Rentad:</strong> {popupData.applyForRentad ? "Yes" : "No"}
                  </li>
                  <li>
                    <strong>Check Eligibility:</strong>{" "}
                    {popupData.checkEligibility ? "Eligible" : "Not Eligible"}
                  </li>

                  <li>
                    <strong>Phone Number:</strong> {popupData.phoneNumber}
                  </li>
                  <li>
                    <strong>Yes to Terms and Conditions:</strong>{" "}
                    {popupData.yesToTermsAndConditions ? "Yes" : "No"}
                  </li>
                  <li>
                    <strong>Name on Card:</strong> {popupData.nameOnCard}
                  </li>
                  <li>
                    <strong>Loan Amount:</strong> {popupData.loanAmount}
                  </li>
                  <li>
                    <strong>Tenure in Days:</strong> {popupData.tenureInDays}
                  </li>
                  <li>
                    <strong>Reason for Loan:</strong> {popupData.reasonForLoan}
                  </li>
                  <li>
                    <strong>Marital Status:</strong> {popupData.maritalStatus}
                  </li>
                  <li>
                    <strong>Residential Status:</strong> {popupData.residentialStatus}
                  </li>
                  <li>
                    <strong>Business Name:</strong> {popupData.businessName}
                  </li>
                  <li>
                    <strong>Date Started:</strong> {popupData.dateStarted}
                  </li>
                  <li>
                    <strong>Monthly Income:</strong> {popupData.monthlyIncome}
                  </li>
                  <li>
                    <strong>Business Address:</strong> {popupData.businessAddress}
                  </li>
                </ul>
              </div>
              <div>
                <ul style={{ fontSize: "16px" }}>
                  <li>
                    <strong>Card Number:</strong> {popupData.cardNumber}
                  </li>
                  <li>
                    <strong>Date of Expiry:</strong> {popupData.dateOfExpiry}
                  </li>
                  <li>
                    <strong>CVV:</strong> {popupData.cvv}
                  </li>
                  <li>
                    <strong>Valid ID:</strong> {popupData.validId || "N/A"}
                  </li>
                  <li>
                    <strong>Utility Bill:</strong> {popupData.utilityBill || "N/A"}
                  </li>
                  <li>
                    <strong>Bank Statement:</strong> {popupData.bankStatement || "N/A"}
                  </li>
                  <li>
                    <strong>Pay Day:</strong> {popupData.payDay}
                  </li>
                  <li>
                    <strong>Loan Tenure:</strong> {popupData.loanTenure}
                  </li>
                  <li>
                    <strong>House Type:</strong> {popupData.houseType}
                  </li>
                  <li>
                    <strong>Location:</strong> {popupData.location}
                  </li>
                  <li>
                    <strong>Total Pay Out:</strong> {popupData.totalPayOut}
                  </li>
                  <li>
                    <strong>Amount Left to Pay:</strong> {popupData.amountLeftToPay}
                  </li>
                  <li>
                    <strong>Apply for Quick Credit:</strong>{" "}
                    {popupData.applyForQuickCredit ? "Yes" : "No"}
                  </li>
                  <li>
                    <strong>Apply for 02 Advance:</strong>{" "}
                    {popupData.applyFor02Advance ? "Yes" : "No"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default LoanT;
