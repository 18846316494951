import Icon from "@mui/material/Icon";
import Dashboard from "layouts/dashboard";
// import InvestmentP from "layouts/iprice";
// import Investment from "layouts/itype";
// import Loans from "layouts/loans";
import CustomerAll from "layouts/customer/listAll";
import Out from "layouts/misc";
import AdminsT from "layouts/admins/table";
import SavingsT from "layouts/savings/table";
import LoanT from "layouts/loans/table";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/admins",
    component: <AdminsT />,
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customers",
    icon: <Icon fontSize="small">manage</Icon>,
    route: "/customers",
    component: <CustomerAll />,
  },
  {
    type: "collapse",
    name: "Savings",
    key: "savings",
    icon: <Icon fontSize="small">savings</Icon>,
    route: "/savings",
    component: <SavingsT />,
  },
  // {
  //   type: "collapse",
  //   name: "Investment Type",
  //   key: "investments",
  //   icon: <Icon fontSize="small">inventory</Icon>,
  //   route: "/investments",
  //   component: <Investment />,
  // },
  // {
  //   type: "collapse",
  //   name: "Investment Price",
  //   key: "price",
  //   icon: <Icon fontSize="small">payment</Icon>,
  //   route: "/price",
  //   component: <InvestmentP />,
  // },
  {
    type: "collapse",
    name: "Loans",
    key: "loans",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/loans",
    component: <LoanT />,
  },

  {
    type: "collapse",
    name: "Log Out",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/log-out",
    component: <Out />,
  },
];

export default routes;
