import React from "react";
import PropTypes from "prop-types";

const ErrorAlert = ({ message }) => {
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // background: "rgba(0, 0, 0, 0.5)",
    background: "rgb(255 255 255 / 76%)",
    zIndex: 9999,
    display: "block",
  };

  const errorAlertStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#f56565",
    color: "#ffffff",
    padding: "1rem",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10000,
  };

  return (
    <>
      <div style={overlayStyle}></div>
      <div style={errorAlertStyle}>
        <strong>{message}</strong>
      </div>
    </>
  );
};

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorAlert;
